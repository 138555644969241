import { apiMap } from 'apiMap';
import config from 'config';
import saveFile from 'file-saver';
import drop from 'lodash/drop';
import reduce from 'lodash/reduce';
import trim from 'lodash/trim';
import { Component } from 'react';
import ReactDom from 'react-dom';

import Button from 'shared/components/button/Button';
import Close from 'shared/components/icon/Close';
import SelectInput from 'shared/components/input/SelectInput';

function setLocal(name, value) {
  try {
    window.localStorage.setItem(name, value);
  } catch {
    window.alert('Failed to save :(');
  }
}

function removeLocal(name) {
  try {
    window.localStorage.removeItem(name);
  } catch {
    window.alert('Failed to save :(');
  }
}

function getLocal(name) {
  try {
    return window.localStorage.getItem(name);
  } catch {
    console.warn('Failed to read local storage');
    return;
  }
}

class DevPanel extends Component {
  state = {
    stateCopied: false,
    actionsCopied: false,
    draftBackend: null,
  };

  ensureSerializableState(state) {
    // Hack for atg state that can't be serialized
    return reduce(
      state,
      (acc, value, key) => {
        if (key === 'atg__view') {
          acc[key] = {
            ...value,
            currentView: 'non-serializable value omitted!',
          };
        } else {
          acc[key] = value;
        }

        return acc;
      },
      {},
    );
  }

  onDownload = type => {
    const { getState, actions } = this.props;
    const data =
      type === 'state' ? this.ensureSerializableState(getState()) : actions;
    const blob = new Blob([JSON.stringify(data)], {
      type: 'application/json;charset=utf-8',
    });
    const name = `${window.location.hostname}${trim(
      window.location.pathname,
      '/',
    )}.${new Date().toISOString()}.${type}.json`;
    saveFile(blob, name);
  };

  onCopy = async type => {
    const { getState, actions } = this.props;
    const data =
      type === 'state' ? this.ensureSerializableState(getState()) : actions;
    const flag = type === 'state' ? 'stateCopied' : 'actionsCopied';

    try {
      await navigator.clipboard.writeText(JSON.stringify(data));

      this.setState({
        [flag]: true,
      });
      window.setTimeout(() => this.setState({ [flag]: false }), 2000);
    } catch (error) {
      window.alert(error);
    }
  };

  onKillSession = () => {
    fetch(`${config.jokerApi}/test/killSession`, {
      method: 'GET',
      credentials: 'include',
    });
    this.props.onClose();
  };

  onDraftBackendChange = e => {
    const { value } = e.target;
    this.setState({
      draftBackend: value,
    });
  };

  onSaveBackend = () => {
    const { draftBackend } = this.state;
    if (draftBackend === '' || draftBackend === null) {
      removeLocal('backend');
    } else {
      setLocal('backend', draftBackend);
    }
    window.location.reload();
  };

  onClearBackend = () => {
    if (getLocal('backend') != null) {
      removeLocal('backend');
      window.location.reload();
    } else {
      this.setState({
        draftBackend: null,
      });
    }
  };

  render() {
    const { onClose } = this.props;
    const { stateCopied, actionsCopied, draftBackend } = this.state;
    const backend = draftBackend ?? getLocal('backend') ?? '';

    const styles = {
      outer: {
        position: 'fixed',
        zIndex: 1000,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(51, 51, 48, 0.7)',
        fontSize: '14px',
      },
      inner: {
        position: 'relative',
        width: '320px',
        overflowY: 'auto',
        background: '#333',
        boxShadow: '2px 0 5px 0 rgba(0,0,0,0.2)',
        borderRadius: '3px',
        padding: '34px 18px 20px',
        display: 'flex',
        flexDirection: 'column',
      },
      closeButton: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        color: 'white',
      },
      fieldset: {
        border: '1px solid #4e4e4e',
        padding: '8px',
        marginTop: '8px',
        marginBottom: '8px',
        color: '#fff',
      },
      buttonGroup: {
        display: 'flex',
      },
      button: {
        backgroundColor: '#4e4e4e',
        color: '#fff',
        padding: '8px 16px',
        borderRadius: '3px',
        width: '100%',
        marginTop: '8px',
        marginLeft: '2px',
        marginRight: '4px',
      },
    };

    return (
      <div style={styles.outer}>
        <div style={styles.inner}>
          <Button style={styles.closeButton} onClick={onClose}>
            <Close />
          </Button>
          {process.env.REACT_APP_BUILD_TARGET === 'stage' && (
            <fieldset style={styles.fieldset}>
              <legend>Backend domain</legend>
              <SelectInput
                style={styles.selectInput}
                width="auto"
                value={backend}
                onChange={this.onDraftBackendChange}
              >
                {Object.keys(apiMap).map(key => (
                  <option key={key} value={key === 'default' ? '' : key}>
                    {key}
                  </option>
                ))}
              </SelectInput>
              <div style={styles.buttonGroup}>
                <Button style={styles.button} onClick={this.onSaveBackend}>
                  Override
                </Button>
                <Button
                  style={styles.button}
                  disabled={backend === ''}
                  onClick={this.onClearBackend}
                >
                  Reset
                </Button>
              </div>
            </fieldset>
          )}
          <fieldset style={styles.fieldset}>
            <legend>State</legend>
            <Button
              style={styles.button}
              onClick={() => this.onDownload('state')}
            >
              Download
            </Button>
            <Button style={styles.button} onClick={() => this.onCopy('state')}>
              {stateCopied ? 'OK!' : 'Copy to clipboard'}
            </Button>
          </fieldset>
          <fieldset style={styles.fieldset}>
            <legend>Action history</legend>
            <Button
              style={styles.button}
              onClick={() => this.onDownload('actions')}
            >
              Download
            </Button>
            <Button
              style={styles.button}
              onClick={() => this.onCopy('actions')}
            >
              {actionsCopied ? 'OK!' : 'Copy to clipboard'}
            </Button>
          </fieldset>
          <fieldset style={styles.fieldset}>
            <legend>Session</legend>
            <Button style={styles.button} onClick={this.onKillSession}>
              Kill the session
            </Button>
          </fieldset>
        </div>
      </div>
    );
  }
}

function ensureSerializableAction(action) {
  // Hack for atg payload that can't be serialized
  if (action.type === 'CHANGE_VIEW') {
    return {
      ...action,
      payload: 'non-serializable payload omitted!',
    };
  }
  return action;
}

const middleware = store => {
  const MAX_COLLECTED = 100;
  let collectedActions = [];

  return next => action => {
    if (action.type === '__DEV_PANEL_SHOW__') {
      const id = '__dev_panel__';
      let panelContainer = document.querySelector(`#${id}`);
      if (panelContainer == null) {
        panelContainer = document.createElement('div');
        panelContainer.id = id;
        document.body.appendChild(panelContainer);
      }
      ReactDom.render(
        <DevPanel
          getState={store.getState}
          actions={collectedActions}
          onClose={() => {
            ReactDom.unmountComponentAtNode(panelContainer);
            panelContainer.remove();
          }}
        />,
        panelContainer,
      );
    } else {
      collectedActions = drop(
        collectedActions,
        collectedActions.push(ensureSerializableAction(action)) - MAX_COLLECTED,
      );

      return next(action);
    }
  };
};

export default middleware;
