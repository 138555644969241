import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';

import { getAtgHighlight } from 'calendar/utils';
import { SPORT_PRODUCTS } from 'shared/constants/AppConstants';
import { formatLargeMoneyAmount } from 'shared/utils/money';
import { getProductDisplayName } from 'shared/utils/productUtils';
import { ANALYSIS_SITES } from 'sport/constants/sportConstants';

export const atgTrendDataSelector = state => state.calendar.atgTrendData;
export const todaysBestBetSelector = state => state.calendar.todaysBestBet;
export const wizSystemsSelector = state => state.calendar.jokerWizSystems;
export const latestTipsSelector = state => state.calendar.latestTips;
export const latestNewsSelector = state => state.calendar.latestNews;
export const atgCalendarSelector = state => state.calendar.atgCalendar;
export const atgJackpotsSelector = state => state.calendar.atgJackpots;
export const sportCalendarSelector = state => state.calendar.sportCalendar;
export const sportJackpotsSelector = state => state.calendar.sportJackpots;
export const sportAnalysisSelector = state => state.calendar.sportAnalysis;

export const getAtgTrendData = createSelector(
  atgTrendDataSelector,
  state => state.data,
);

export const isAtgTrendDataLoading = createSelector(
  atgTrendDataSelector,
  state => state.loading,
);

export const getAtgTrendDataError = createSelector(
  atgTrendDataSelector,
  state => state.error,
);

export const getTodaysBestBet = createSelector(
  todaysBestBetSelector,
  state => state.betData,
);

export const isTodaysBestBetLoading = createSelector(
  todaysBestBetSelector,
  state => state.loading,
);

export const getTodaysBestBetError = createSelector(
  todaysBestBetSelector,
  state => state.error,
);

export const getAvailableSystems = createSelector(
  wizSystemsSelector,
  state => state.availableSystems,
);

export const getSystemById = createSelector(
  [wizSystemsSelector, (_, systemId) => systemId],
  (state, systemId) =>
    state.availableSystems.find(system => system.id === systemId),
);

export const isAvailableSystemsLoading = createSelector(
  wizSystemsSelector,
  state => state.loading,
);

export const getAvailableSystemsError = createSelector(
  wizSystemsSelector,
  state => state.error,
);

export const getTotalTipsCount = createSelector(
  latestTipsSelector,
  tips => tips.count,
);

export const getLatestTipsError = createSelector(
  latestTipsSelector,
  tips => tips.error,
);

export const getLatestTipsItems = createSelector(
  latestTipsSelector,
  tips => tips.items,
);

export const isLatestTipsLoading = createSelector(
  latestTipsSelector,
  tips => tips.loading,
);

export const getTotalNewsCount = createSelector(
  latestNewsSelector,
  news => news.count,
);

export const getLatestNewsError = createSelector(
  latestNewsSelector,
  news => news.error,
);

export const getLatestNewsItems = createSelector(
  latestNewsSelector,
  news => news.items,
);

export const isLatestNewsLoading = createSelector(
  latestNewsSelector,
  news => news.loading,
);

export const getAtgCalendar = createSelector(atgCalendarSelector, calendar =>
  calendar.resolved.map(day => ({
    ...day,
    betParamsList: day.betTypes.map(betType => ({
      betType: betType.betType,
      trackKey:
        betType.sharedTrackKey != null ? betType.sharedTrackKey : day.trackKey,
      date: day.date,
    })),
  })),
);

export const hasAtgCalendar = createSelector(
  atgCalendarSelector,
  calendar => calendar.timestamp !== null,
);

export const getAtgCalendarError = createSelector(
  atgCalendarSelector,
  calendar => calendar.error,
);

/**
 * @return {array} - An array of all sport events decorated with
 * betParams object and possible jackpot value
 */
export const getSportEvents = createSelector(
  [sportCalendarSelector, sportJackpotsSelector, sportAnalysisSelector],
  (calendar, jackpots, analyses) =>
    calendar.resolved
      .map(event => {
        const { product, drawNr, ...rest } = event;
        const jackpot = jackpots.resolved.find(
          jackpot => jackpot.product === product && jackpot.drawNr === drawNr,
        );
        const hasAnalysis = (site, analyses) =>
          analyses[site].resolved.some(
            analysis =>
              analysis.svenskaspel_id === drawNr && analysis.content.length > 0,
          );
        const hasOveroddsAnalysis = hasAnalysis(
          ANALYSIS_SITES.OVERODDS,
          analyses,
        );
        const hasJokerSportAnalysis = hasAnalysis(
          ANALYSIS_SITES.JOKER_SPORT,
          analyses,
        );

        return {
          ...rest,
          jackpotAmount: jackpot ? jackpot.amount : undefined,
          betParams: { product, drawNr },
          hasOveroddsAnalysis,
          hasJokerSportAnalysis,
        };
      })
      .sort((a, b) => a.closeTime - b.closeTime),
);

/**
 * @return {array} - An array of arrays with sport events grouped by date
 */
export const getSportCalendar = createSelector(getSportEvents, calendar => [
  ...calendar
    .reduce((acc, event) => {
      const date = new Date(event.closeTime).getDate();
      if (Array.isArray(acc.get(date))) {
        acc.get(date).push(event);
      } else {
        acc.set(date, [event]);
      }
      return acc;
    }, new Map())
    .values(),
]);

export const hasSportCalendar = createSelector(
  sportCalendarSelector,
  calendar => calendar.timestamp !== null,
);

export const getSportCalendarError = createSelector(
  sportCalendarSelector,
  calendar => calendar.error,
);

export const getJackpots = createSelector(
  [sportJackpotsSelector, atgJackpotsSelector],
  (sportJackpots, atgJackpots) =>
    orderBy(
      [
        ...sportJackpots.resolved.map(jackpot => {
          const { amount, date, product, drawNr } = jackpot;
          return {
            isSport: true,
            amount,
            product,
            date,
            betParams: {
              product,
              drawNr,
            },
          };
        }),
        ...atgJackpots.resolved.map(({ betKey, jackpot }) => {
          const { sum, currency } = jackpot;
          const { betType, trackKey, date } = betKey;
          return {
            isAtg: true,
            amount: formatLargeMoneyAmount(sum, {
              currency,
              shortenMillionSuffix: true,
            }),
            product: betType,
            date,
            betParams: {
              betType,
              trackKey,
              date,
            },
          };
        }),
      ],
      'date',
      'asc',
    ),
);

export const hasJackpots = createSelector(
  [sportJackpotsSelector, atgJackpotsSelector],
  (sportJackpots, atgJackpots) =>
    atgJackpots.timestamp !== null &&
    (sportJackpots.timestamp !== null || !INCLUDE_SPORT),
);

export const isLoadingJackpots = createSelector(
  [sportJackpotsSelector, atgJackpotsSelector],
  (sportJackpots, atgJackpots) =>
    sportJackpots.isLoading && atgJackpots.isLoading,
);

export const getJackpotsError = createSelector(
  [sportJackpotsSelector, atgJackpotsSelector],
  (sportJackpots, atgJackpots) => sportJackpots.error || atgJackpots.error,
);

export const isAtgSectionVisible = state =>
  state.calendar.atgCalendar.isSectionVisible;

export const isAtgListVisible = state =>
  state.calendar.atgCalendar.isListVisible;

export const isSportSectionVisible = state =>
  state.calendar.sportCalendar.isSectionVisible;

export const isSportListVisible = state =>
  state.calendar.sportCalendar.isListVisible;

/**
 * @return {array} - An array of events to highlight
 */
export const getAtgCalendarHighlights = createSelector(
  [atgCalendarSelector, atgJackpotsSelector],
  (calendar, atgJackpots) => {
    const { resolved: raceDays } = calendar;
    const { resolved: jackpots } = atgJackpots;

    const result = orderBy(
      [
        getAtgHighlight('V4', raceDays, jackpots),
        getAtgHighlight('V64', raceDays, jackpots),
        getAtgHighlight('V65', raceDays, jackpots),
        getAtgHighlight('V75', raceDays, jackpots),
        getAtgHighlight('GS75', raceDays, jackpots),
        getAtgHighlight('V86', raceDays, jackpots),
      ].filter(Boolean),
      'startTime',
      'asc',
    );

    return result;
  },
);

/**
 * @return {array} - An array of events to highlight
 */
export const getSportCalendarHighlights = createSelector(
  getSportEvents,
  calendar =>
    [...new Set(SPORT_PRODUCTS.map(product => getProductDisplayName(product)))]
      .map(productName =>
        calendar.find(
          event =>
            getProductDisplayName(event.betParams.product) === productName,
        ),
      )
      .filter(Boolean)
      .sort((a, b) => a.closeTime - b.closeTime),
);

export const getBombenEvents = createSelector(getSportEvents, events =>
  events.filter(event => event.betParams.product === 'Bomben'),
);
